import React, { Component, useState } from "react";
import InnerPageHeader from "../common/InnerPageHeader";
import Select from "react-select";
import { Button, Modal } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import {
  settleMarketRequest,
  cancelMarketRequest,
  cancelFancyRequest
} from "../../_actions/settlemarket/settlemarket.actions";
import {
  getAllSports,
  getMatchRollBack,
  getMatchResult,
  getManageFancy,
  getActiveEventList,
  updateSettleFancy,
  rollbackMatch
} from "_services/settlements.services";
import ClipLoader from "react-spinners/ClipLoader";

import { settlementCancelMarket } from "../../_services/settlemarket.services"
import { roleBackSettlement } from "../../_services/settlements.services"

import { toast } from "react-toastify";
import moment from "moment";
import { formatDateAndTime } from "../../utils/index"
import ConfrimModal from "components/ConfirmModal";
import Loader from "components/common/Loader";

const currentUrl = {
  matchResult: "/match-result",
  matchRollback: "/match-rollback",
  manageFancy: "/manage-fancy",
};

class List extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    responseData: [],
    totalRecords: 0,
    currentPage: 1,
    pageTitle: "",
    breadcrumb: "",
    breadcrumbPath: "",
    action: "",
    selectedSports: 4,
    sportsData: [],
    eventData: [],
    eventOptions: [],
    selectedEvent: "",
    eventId: "",
    selectedPage: { value: 1, label: "1 - 20" },
    pageOptions: [],
    dataPerPage: 20,
    result: "",
    selectedResult: "",
    page: 1,
    isSportLoading: true
  };

  componentDidMount() {
    const { pathname } = this.props.location;
    if (pathname) {
      if (pathname === currentUrl.matchResult) {
        this.setState({
          pageTitle: "Match Result",
          breadcrumb: "Match",
          breadcrumbPath: "/",
          action: "Exchange",
        });
        this.fetchMatchResult();
        this.fetchAllSports();
        this.fetchActiveEvent();
      } else if (pathname === currentUrl.matchRollback) {
        this.setState({
          pageTitle: "Match Result",
          breadcrumb: "Match",
          breadcrumbPath: "/",
          action: "Exchange",
        });
        this.fetchMatchRollBack();
        this.fetchAllSports();
      } else if (pathname === currentUrl.manageFancy) {
        this.setState({
          pageTitle: "Match Fancy",
          breadcrumb: "Fancy",
          breadcrumbPath: "/",
          action: "Session",
        });
        this.fetchActiveEvent();
        this.fetchManageFancy();
      } else {
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { pathname } = this.props.location;
    if (pathname === currentUrl.matchRollback) {
      if (
        prevState.selectedSports !== this.state.selectedSports ||
        JSON.stringify(prevState.page) !== JSON.stringify(this.state.page)
      ) {
        if (this.state.selectedSports === "") {
          this.fetchMatchRollBack();
        } else {
          this.fetchMatchRollBack(this.state.selectedSports);
        }
      }
    }

    if (pathname === currentUrl.matchResult) {
      if (
        prevState.selectedSports !== this.state.selectedSports ||
        JSON.stringify(prevState.page) !== JSON.stringify(this.state.page)
      ) {
        this.setState({ selectedEvent: "" });
        if (this.state.selectedSports === "") {
          this.fetchMatchResult();
        } else {
          this.fetchMatchResult(this.state.selectedSports);
          this.fetchActiveEvent(this.state.selectedSports);
        }
      }
    }

    if (
      pathname === currentUrl.matchResult ||
      pathname === currentUrl.manageFancy
    ) {
      if (
        JSON.stringify(prevState.eventData) !==
        JSON.stringify(this.state.eventData) ||
        JSON.stringify(prevState.page) !== JSON.stringify(this.state.page)
      ) {
        let eventOptions = this.state.eventData.map((val) => {
          return {
            label: val.event_name,
            value: val.event_id,
          };
        });
        eventOptions.unshift({ label: "Select", value: "" });
        this.setState({ eventOptions: eventOptions });
      }
    }
  }

  setResult = (val, event_id) => {
    if (val) {
      this.setState({ result: val, selectedResult: event_id });
    } else {
      this.setState({ result: "", selectedResult: "" });
    }
  };

  fetchAllSports = () => {
    getAllSports()
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.length
        ) {
          this.setState({ sportsData: response.data.data });
        } else {
          this.setState({ sportsData: [] });
        }
      })
      .catch((error) => {
        this.setState({ sportsData: [] });
      });
  };

  fetchActiveEvent = (sportIdValue = this.state.selectedSports) => {
    let sendData = {};
    sendData.items_perpage = 100;
    sendData.current_page = 1;
    sendData.sport_id = sportIdValue;
    sendData.event_id = "";

    getActiveEventList(sendData)
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.records &&
          response.data.data.records.length
        ) {
          this.setState({ eventData: response.data.data.records });
        } else {
          this.setState({ eventData: [] });
        }
      })
      .catch((error) => {
        this.setState({ eventData: [] });
      });
  };

  setPageOptions = (totalData) => {
    try {
      if (totalData && parseInt(totalData)) {
        let pages = Math.ceil(parseInt(totalData) / this.state.dataPerPage);
        let opt = [];
        for (let i = 1; i <= pages; i++) {
          let val = {};
          val.value = i;
          let a = i - 1;
          if (i == pages) {
            val.label = `${a * 20 + 1} - ${totalData}`;
          } else {
            val.label = `${a * 20 + 1} - ${a * 20 + 20}`;
          }
          opt.push(val);
        }
        if (this.state.page == 1) {
          this.setState({ pageOptions: opt, selectedPage: opt[0] });
        } else {
          this.setState({ pageOptions: opt });
        }
      }
    } catch (error) { }
  };

  fetchMatchRollBack = (sportIdValue = "") => {
    let sendData = {};
    sendData.items_perpage = 20;
    sendData.current_page = this.state.page;
    sendData.sport_id = sportIdValue;
    this.setState({ isSportLoading: true })
    getMatchRollBack(sendData)
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.total_records
        ) {
          this.setState({
            responseData: response.data.data.records,
            totalRecords: response.data.data.total_records,
            isSportLoading: false
          });
          this.setPageOptions(response.data.data.total_records);
        } else {
          this.setState({
            responseData: [],
            totalRecords: 0,
            pageOptions: [],
            selectedPage: { value: 1, label: "0 - 0" },
            isSportLoading: false
          });
        }
      })
      .catch((error) => {
        this.setState({
          responseData: [],
          totalRecords: 0,
          pageOptions: [],
          selectedPage: { value: 1, label: "0 - 0" },
          isSportLoading: false
        });
      });
  };

  fetchMatchResult = (sportIdValue = "", eventIdValue = "") => {
    let sendData = {};
    sendData.items_perpage = 20;
    sendData.current_page = this.state.page;
    sendData.sport_id = this.state.selectedSports;
    // sendData.event_id = eventIdValue;
    sendData.event_id = (this.state.selectedEvent && this.state.selectedEvent != '') ? this.state.selectedEvent.value : null;
    this.setState({ isSportLoading: true })
    getMatchResult(sendData)
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.total_records
        ) {
          this.setState({
            responseData: response.data.data.records,
            totalRecords: response.data.data.total_records,
            isSportLoading: false
          });
          this.setPageOptions(response.data.data.total_records);
        } else {
          this.setState({
            responseData: [],
            totalRecords: 0,
            pageOptions: [],
            selectedPage: { value: 1, label: "0 - 0" },
            isSportLoading: false
          });
        }
      })
      .catch((error) => {
        this.setState({
          responseData: [],
          totalRecords: 0,
          pageOptions: [],
          selectedPage: { value: 1, label: "0 - 0" },
          isSportLoading: false
        });
      });
  };

  fetchManageFancy = (eventIdValue = this.state.selectedEvent?.value) => {

    let sendData = {};
    sendData.items_perpage = 100;
    sendData.current_page = this.state.page;
    sendData.event_id = eventIdValue;
    this.setState({ isSportLoading: true })
    getManageFancy(sendData)
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.total_records
        ) {
          this.setState({
            responseData: response.data.data.records,
            totalRecords: response.data.data.total_records,
            isSportLoading: false
          });
          this.setPageOptions(response.data.data.total_records);
        } else {
          this.setState({
            responseData: [],
            totalRecords: 0,
            pageOptions: [],
            selectedPage: { value: 1, label: "0 - 0" },
            isSportLoading: false
          });
        }
      })
      .catch((error) => {
        this.setState({
          responseData: [],
          totalRecords: 0,
          pageOptions: [],
          selectedPage: { value: 1, label: "0 - 0" },
          isSportLoading: false
        });
      });
  };

  postSettleFancy = (event_id, session_id, callback) => {

    let sendData = {};
    sendData.session_id = session_id;
    sendData.event_id = event_id;
    sendData.result = this.state.result;
    this.setState({ isSportLoading: true })
    updateSettleFancy(sendData)
      .then((response) => {

        if (response) {

          let sendData = {};
          sendData.items_perpage = 20;
          sendData.current_page = this.state.page;
          sendData.event_id = (this.state.selectedEvent && this.state.selectedEvent != '') ? this.state.selectedEvent.value : null;

          getManageFancy(sendData)
            .then((response) => {
              if (
                response &&
                response.data &&
                response.data.data &&
                response.data.data.total_records
              ) {
                this.setState({
                  responseData: response.data.data.records,
                  totalRecords: response.data.data.total_records,
                  isSportLoading: false
                });
                this.setPageOptions(response.data.data.total_records);
              } else {
                this.setState({
                  responseData: [],
                  totalRecords: 0,
                  pageOptions: [],
                  selectedPage: { value: 1, label: "0 - 0" },
                  isSportLoading: false
                });
              }
            })
            .catch((error) => {
              this.setState({
                responseData: [],
                totalRecords: 0,
                pageOptions: [],
                selectedPage: { value: 1, label: "0 - 0" },
                isSportLoading: false
              });
            });

          this.setState({ result: "", selectedResult: "" });
          // toast.success("Submitted Successfully");
          toast.success(
            <div>
              <i
                className="fa fa-check"
                style={{ fontWeight: "bold", marginRight: "1rem" }}
                aria-hidden="true"
              ></i>
              <span>Submitted Successfully</span>
            </div>,
            {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            }
          );

        }
        callback(true)
      })
      .catch((error) => { });
  };

  getRecordDetails = () => {
    const { currentPage, totalRecords } = this.state;
    let startingCount = currentPage === 1 ? 1 : currentPage * 10 + 1;
    let endingCount = currentPage === 1 ? totalRecords : totalRecords * 20;
    return `Showing ${startingCount} to ${endingCount} of ${totalRecords} entries`;
  };

  handleChange = (selectedEvent) => {
    try {
      const { pathname } = this.props.location;
      this.setState({ selectedEvent: selectedEvent, page: 1 }, () => {
        if (pathname === currentUrl.matchResult) {
          if (selectedEvent && selectedEvent.value) {
            this.fetchMatchResult(this.state.selectedSports, selectedEvent.value);
          } else {
            this.fetchMatchResult(this.state.selectedSports);
          }
        }

        if (pathname === currentUrl.manageFancy) {
          if (selectedEvent && selectedEvent.value) {
            this.fetchManageFancy(selectedEvent.value);
          } else {
            this.fetchManageFancy();
          }
        }
      });

    } catch (error) { }
  };

  handlePaginationChange = (selectedPage) => {
    this.setState({ selectedPage: selectedPage, page: selectedPage.value });
  };

  abandon = () => { };

  render() {
    const {
      totalRecords,
      selectedSports,
      sportsData,
      responseData,
      selectedEvent,
      eventOptions,
      pageOptions,
      selectedPage,
      result,
      selectedResult,
    } = this.state;
    const { pathname } = this.props.location;
    return (
      <>
        <InnerPageHeader
          pageTitle={this.state.pageTitle}
          breadcrumbs={[
            { title: "Dashboard", url: "/" },
            { title: this.state.breadcrumb, url: this.state.breadcrumbPath },
          ]}
          currenPageTitle="List"
        />
        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox float-e-margins">
                <div className="ibox-title">
                  <div className="col-container mx-0 px-2 row">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-8 col">
                      {pathname === currentUrl.manageFancy ? (
                        <Select
                          options={eventOptions}
                          value={selectedEvent}
                          onChange={this.handleChange}
                        />
                      ) : (
                        <div className="btn-filter mt-3 mb-3">
                          {sportsData && sportsData.length ? (
                            <>
                              <li
                                className={
                                  selectedSports === "" ? "active mt-1" : "mt-1"
                                }
                                onClick={() =>
                                  this.setState({
                                    selectedSports: "",
                                    page: 1,
                                    selectedEvent: ''
                                  })
                                }
                              >
                                All
                              </li>
                              {sportsData.map((sports, index) => (
                                <li
                                  key={index}
                                  className={
                                    selectedSports === sports.sport_id
                                      ? "active mt-1"
                                      : "mt-1"
                                  }
                                  onClick={() =>
                                    this.setState({
                                      selectedSports: sports.sport_id,
                                      page: 1,
                                      selectedEvent: ''
                                    })
                                  }
                                >
                                  {sports.sports_name}
                                </li>
                              ))}
                            </>
                          ) : null}
                        </div>
                      )}
                    </div>
                    {pathname === currentUrl.matchResult &&
                      this.state.selectedSports ? (
                      <>
                        <div className="col-12 col-sm-12 col-md-3 col-lg-2 col">
                          <Select
                            // options={options}
                            value={selectedPage}
                            options={pageOptions}
                            onChange={this.handlePaginationChange}
                          />
                        </div>
                        <div className="col-12 col-sm-12 col-md-3 col-lg-2 col">
                          <Select
                            options={eventOptions}
                            value={selectedEvent}
                            onChange={this.handleChange}
                          />
                        </div>
                      </>
                    ) : (
                      <div className="col-12 col-sm-12 col-md-6 col-lg-4 col">
                        <Select
                          options={pageOptions}
                          value={selectedPage}
                          onChange={this.handlePaginationChange}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="ibox-content">
                  <div className="table-responsive">
                    <div
                      id="DataTables_Table_0_wrapper"
                      className="dataTables_wrapper form-inline dt-bootstrap"
                    >
                      {pathname === currentUrl.matchRollback ? (
                        <MatchRollBackTable responseData={responseData} roleBackList={() => this.fetchMatchRollBack()} />
                      ) : null}
                      {pathname === currentUrl.matchResult ? (
                        <MatchResultTable
                          responseData={responseData}
                          fetchMatchResult={this.fetchMatchResult}
                          {...this.props}
                        />
                      ) : null}
                      {pathname === currentUrl.manageFancy ? (
                        <ManageFancyTable
                          responseData={responseData}
                          postSettleFancy={this.postSettleFancy}
                          result={result}
                          setResult={this.setResult}
                          selectedResult={selectedResult}
                          fetchManageFancy={this.fetchManageFancy}
                          {...this.props}

                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </>
    );
  }
}

class MatchRollBackTable extends React.Component {
  constructor(props) {
    super(props);

  }
  state = {
    showConfirmationModal: false,
    matchData: '',
    showLoader: false,
    rollbackCode: ''
  }

  onRollback = () => {
    this.setState({ showLoader: true })

    let sendData = {};
    sendData.event_id = this.state.matchData.event_id;
    sendData.code = this.setState.rollbackCode

    rollbackMatch(sendData)
      .then((response) => {
        this.setState({ showConfirmationModal: false, showLoader: false, rollbackCode: "" })
        this.props.roleBackList();

      })
      .catch((error) => {
        this.setState({ showConfirmationModal: false, showLoader: false })
        if (error?.response?.data?.error) {
          toast.error(error.response.data.error);

        }
      });
  }

  showConfirmModal = (match) => {
    this.setState({ showConfirmationModal: true, matchData: match });
  };

  hideConfirmModal = () => {
    this.setState({ showConfirmationModal: false, showLoader: false, rollbackCode: '' });
  };

  render() {
    const { showConfirmationModal, matchData, showLoader, rollbackCode } = this.state;
    const { responseData } = this.props;

    return (
      <>
        <table className="table table-striped table-bordered table-hover dataTables-example">
          <thead>
            <tr>
              <th>S.NO</th>
              <th>DATE</th>
              <th>SPORTS</th>
              <th>MATCH NAME</th>
              <th>SETTLEMENT DATE</th>
              <th>ACTION</th>
            </tr>
          </thead>
          <tbody>
            {this.state.isSportLoading && <Loader />}
            {responseData && responseData.length ? (
              responseData.map((match, index) => (
                <tr className="gradeX" key={index}>
                  <td>{index + 1}</td>
                  <td id="getdate_2794093">
                    <i className="fa fa-clock-o"></i>&nbsp;
                    {/* {match.open_date} */}
                    {formatDateAndTime('MMM DD, hh:mm:ss A', match.open_date)}
                  </td>
                  <td>{match.sports_name}</td>
                  <td className="center">{match.event_name}</td>
                  <td className="center">
                    <i className="fa fa-clock-o"></i>&nbsp;
                    {/* {match.settlement_date} */}
                    {formatDateAndTime('MMM DD, hh:mm:ss A', match.settlement_date)}
                  </td>
                  <td>
                    <button
                      type="button"
                      className="action-btn mt-1 btn btn-primary"
                      onClick={() => this.showConfirmModal(match)}
                    >
                      Roll Back
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr className="gradeX">
                <td colSpan={6}>No data available in table</td>
              </tr>
            )}
          </tbody>
        </table>

        <Modal
          show={showConfirmationModal}
          onHide={() => !showLoader && this.hideConfirmModal()}
          style={{ opacity: 1 }}
        >
          <Modal.Header closeButton>Confrim Message</Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>
                Please enter 4 digit's verification code.
              </label>
              <input
                placeholder="verification code"
                className="form-control" value={rollbackCode} onChange={(e) => this.setState({ rollbackCode: e.target.value })} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => !showLoader && this.hideConfirmModal()}>
              No
            </Button>
            <Button
              type="button"
              onClick={() => !showLoader && this.onRollback()}
              disabled={rollbackCode.length !== 4}
            >
              {showLoader ? <ClipLoader color={"#FFFFFF"} loading={true} size={16} /> : 'Submit'}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

class MatchResultTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formObj: {
        selection_id: "",
        market_id: "",
        event_id: "",
      },
      formObjTie: {
        selection_id: "",
        market_id: "",
        event_id: "",
      },
      show: false,
      show_confirm: false,
      show_abondon_confirm: false,
      loading_abondon_confirm: false,
      tie: false,
      tie_confirm: false,
      show_tie_confirm: false,

      disable: false,
      disable_confirm: false,
      name: "",
      listItems: "",
      market_name: "",
      event_name: "",
      selection_name: "",
      settled_flag: 0,
      responseData: [],
      opac: {
        opacity: 0,
      },
      opac_confirm: {
        opacity: 0,
      },
      loadingSettleMarket: false,
      tieCode: '',
      abandonCode: '',
    };
  }

  openModal = (market_id, event_id, run, market_name, event_name) => {
    const runners = JSON.parse(run);
    const listItems = runners.map(({ selectionId, runnerName }) => (
      <option key={selectionId} value={selectionId}>
        {runnerName}
      </option>
    ));
    this.setState({
      show: !this.state.show,
      disable: true,
      listItems: listItems,
      formObj: { event_id: event_id.toString(), market_id: market_id },
      market_name: market_name,
      event_name: event_name,
      opac: { opacity: 1 },
    });
  };

  handleModal = () => {
    this.setState({ show: !this.state.show });
  };

  openModal_confirm = () => {
    this.setState({
      opac: { opacity: 0 },
      opac_confirm: { opacity: 1 },
      show_confirm: !this.state.show_confirm,
    });
  };

  handleModal_confirm = () => {
    this.setState({
      show_confirm: !this.state.show_confirm,
      opac: { opacity: 1 },
      opac_confirm: { opacity: 0 },
    });
  };

  openModal_abondon_confirm = (market_id, event_name, event_id) => {
    this.setState({
      show_abondon_confirm: !this.state.show_abondon_confirm,
      formObj: { market_id, type: 1, event_id: event_id },
      event_name
    });
  };

  openModal_tie_confirm = (market_id, event_name, event_id) => {
    this.setState({
      show_tie_confirm: !this.state.show_tie_confirm,
      formObjTie: { market_id, type: 2, event_id: event_id },
      event_name
    });
  };

  handleModal_abondon_confirm = () => {
    this.setState({ show_abondon_confirm: !this.state.show_abondon_confirm });
  };

  handleModal_tie_confirm = () => {
    this.setState({ show_tie_confirm: !this.state.show_tie_confirm });
  };

  handleInput = (e) => {
    const { name, value } = e.target; // <-- moved outside asynchronous context
    var index = e.nativeEvent.target.selectedIndex;
    this.state.selection_name = e.nativeEvent.target[index].text;
    //this.setState({disable:false,selectionId:value});
    this.setState((state) => ({
      disable: false,
      formObj: {
        ...state.formObj,
        [name]: value,
      },
    }));
  };

  submit_confirm = (e) => {
    e.preventDefault();
    this.handleModal();
    this.openModal_confirm();
  };

  submit = (e) => {
    e.preventDefault();
    this.setState({
      loadingSettleMarket: true
    });
    let { formObj } = this.state;
    this.props.dispatch(
      settleMarketRequest(formObj, (res) => {
        if (res) {

          this.setState({
            settled_flag: 1,
            show: false,
            show_confirm: false,
            opac_confirm: { opacity: 0 },
            opac: { opacity: 0 },
            loadingSettleMarket: false
          });
          this.props.fetchMatchResult();

          alert(res.message);

        } else {
          this.setState({
            loadingSettleMarket: false
          });
        }
      })
    );
  };

  submit_abondon = () => {

    this.setState({ loading_abondon_confirm: true });
    let { formObj } = this.state;
    formObj.code = this.state.abandonCode

    this.props.dispatch(
      cancelMarketRequest(formObj, (res) => {

        if (res) {
          this.props.fetchMatchResult();
          this.setState({ show_abondon_confirm: false, loading_abondon_confirm: false, abandonCode: '' });
          // alert(res.message);
        } else {
          this.setState({ loading_abondon_confirm: false });
        }
      })
    );
  };

  submit_tie = () => {

    let { formObjTie } = this.state;
    this.setState({ loading_tie_confirm: true });
    formObjTie.code = this.state.tieCode

    this.props.dispatch(
      cancelMarketRequest(formObjTie, (res) => {

        if (res) {
          this.props.fetchMatchResult();
          this.setState({ show_tie_confirm: false, loading_tie_confirm: false, tieCode: '' });
          // alert(res.message);
        } else {
          this.setState({ loading_tie_confirm: false });
        }
      })
    );
  };

  render() {
    let responseData = [];
    let ch = 0;
    responseData = this.props.responseData;
    const {
      redirect,
      show,
      show_confirm,
      show_abondon_confirm,
      loading_abondon_confirm,
      show_tie_confirm,
      loading_tie_confirm,
      listItems,
      market_name,
      event_name,
      selection_name,
      opac,
      opac_confirm,
    } = this.state;
    if (redirect) {
      return <Redirect to={redirect} />;
    }
    return (
      <>
        <table className="table table-striped table-bordered table-hover dataTables-example">
          <thead>
            <tr>
              <th>S.NO</th>
              <th>DATE</th>
              <th>SPORTS</th>
              <th>SERIES NAME</th>
              <th>MATCH NAME</th>
              <th>MARKET NAME</th>
              <th>MARKET ID</th>
              <th>ACTION</th>
            </tr>
          </thead>
          <tbody>
            {this.state.isSportLoading && <Loader />}
            {responseData && responseData.length ? (
              responseData.map((match, index) => (
                <tr className="gradeX" key={index}>
                  <td>{index + 1}</td>
                  <td id="getdate_2794093">
                    <div>
                      <i className="fa fa-clock-o"></i>&nbsp;
                      {match.market_start_time ?
                        // match.market_start_time 
                        formatDateAndTime('MMM DD, hh:mm:ss A', match.market_start_time)
                        :
                        "-"
                      }
                    </div>

                    {match.is_main_market ? (
                      <div>
                        <span
                          className="d-block"
                          style={{ fontWeight: "bold" }}
                        >
                          Main Market (Settle last)
                        </span>
                      </div>
                    ) : null}
                  </td>
                  <td>{match.sports_name ? match.sports_name : "-"}</td>
                  <td className="center">
                    {match.league_name ? match.league_name : "-"}
                  </td>
                  <td className="center">
                    {match.event_name ? match.event_name : "-"}
                  </td>
                  <td className="center">
                    {match.market_name ? match.market_name : "-"}
                  </td>
                  <td className="">
                    {match.market_id ? match.market_id : "-"}
                  </td>
                  <td>
                    <button
                      type="button"
                      className="action-btn mt-1 btn btn-primary"
                      onClick={() =>
                        this.openModal(
                          match.market_id,
                          match.event_id,
                          match.runners,
                          match.market_name,
                          match.event_name
                        )
                      }
                    >
                      Result
                    </button>{" "}
                    <button
                      type="button"
                      className="action-btn mt-1 btn btn-primary"
                      onClick={() =>
                        this.openModal_abondon_confirm(
                          match.market_id,
                          match.event_name,
                          match.event_id
                        )
                      }
                    >
                      Abandon
                    </button>{" "}
                    {match.is_main_market ? (
                      <button
                        type="button"
                        className="action-btn mt-1 btn btn-primary"
                        onClick={() =>
                          this.openModal_tie_confirm(
                            match.market_id,
                            match.event_name,
                            match.event_id
                          )}
                      >
                        Tie
                      </button>
                    ) : null}
                  </td>
                </tr>
              ))
            ) : (
              <tr className="gradeX">
                <td colSpan={8}>No data available in table</td>
              </tr>
            )}
          </tbody>
        </table>

        <Modal show={show} onHide={() => this.handleModal()} style={opac}>
          <Modal.Header closeButton>Result Declare - Match Odds</Modal.Header>
          <form onSubmit={this.submit_confirm}>
            <Modal.Body>
              <div className="form-group" onChange={(e) => this.handleInput(e)}>
                <label>Select Selection Name</label>
                <select
                  className="form-control required"
                  id="selection_id"
                  name="selection_id"
                  onChange={(e) => this.handleInput(e)}
                >
                  <option value="">Select...</option>
                  {listItems}
                </select>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => this.handleModal()}>Close</Button>
              <Button
                onClick={() => this.handleModal()}
                type="submit"
                disabled={this.state.disable}
              >
                Save
              </Button>
            </Modal.Footer>
          </form>
        </Modal>

        <Modal
          show={show_confirm}
          onHide={() => this.handleModal_confirm()}
          style={opac_confirm}
        >
          <Modal.Header closeButton>Confrim Message</Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>
                Are you sure you want to declared result{" "}
                <span style={{ color: "blue" }}>{market_name}</span>{" "}
                <span style={{ color: "red" }}>{selection_name}</span> at{" "}
                <span style={{ color: "red" }}>{event_name}</span> ?
              </label>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" onClick={(e) => !this.state.loadingSettleMarket && this.submit(e)}>
              {this.state.loadingSettleMarket ? <ClipLoader color={"#FFFFFF"} loading={this.state.loadingSettleMarket} size={16} /> : 'Yes'}
            </Button>
            <Button onClick={() => this.handleModal_confirm()}>Cancel </Button>

          </Modal.Footer>
        </Modal>

        {/* {show_abondon_confirm &&
          <ConfrimModal
            isShow={show_abondon_confirm}
            message={
              <label>
                Are you sure you want to abondon{" "}
                <span style={{ color: "red" }}>{event_name}</span> match?
              </label>
            }
            onCancle={() => this.handleModal_abondon_confirm()}
            onSubmit={() => this.submit_abondon()}
            loading={loading_abondon_confirm}
          />} */}

        <Modal
          show={show_abondon_confirm}
          onHide={() => !loading_abondon_confirm && this.handleModal_abondon_confirm()}
          style={{ opacity: 1 }}
        >
          <Modal.Header closeButton>Confrim Message</Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>
                Please enter 4 digit's verification code.
              </label>
              <input
                placeholder="verification code"
                className="form-control" value={this.state.abandonCode} onChange={(e) => this.setState({ abandonCode: e.target.value })} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => !loading_abondon_confirm && this.handleModal_abondon_confirm()}>
              No
            </Button>
            <Button
              type="button"
              onClick={() => !loading_abondon_confirm && this.submit_abondon()}
              disabled={!this.state.abandonCode.length}
            >
              {loading_abondon_confirm ? <ClipLoader color={"#FFFFFF"} loading={true} size={16} /> : 'Submit'}
            </Button>
          </Modal.Footer>
        </Modal>

        {/* {show_tie_confirm &&
          <ConfrimModal
            isShow={show_tie_confirm}
            message={
              <label>
                Are you sure you want to tie{" "}
                <span style={{ color: "red" }}>{event_name}</span> match?
              </label>
            }
            onCancle={() => this.handleModal_tie_confirm()}
            onSubmit={() => this.submit_tie()}
            loading={loading_tie_confirm}
          />} */}

        <Modal
          show={show_tie_confirm}
          onHide={() => !loading_tie_confirm && this.handleModal_tie_confirm()}
          style={{ opacity: 1 }}
        >
          <Modal.Header closeButton>Confrim Message</Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>
                Please enter 4 digit's verification code.
              </label>
              <input
                placeholder="verification code"
                className="form-control" value={this.state.tieCode} onChange={(e) => this.setState({ tieCode: e.target.value })} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => !loading_tie_confirm && this.handleModal_tie_confirm()}>
              No
            </Button>
            <Button
              type="button"
              onClick={() => !loading_tie_confirm && this.submit_tie()}
              disabled={!this.state.tieCode.length}
            >
              {loading_tie_confirm ? <ClipLoader color={"#FFFFFF"} loading={true} size={16} /> : 'Submit'}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

class ManageFancyTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show_fancyabondon_confirm: false,
      show_confirm_pop_up: false,
      loadingFancyabondon: false,
      abandonCode: ''
    }
  }


  openModal_fancyabondon_confirm = (session_id, event_name, event_id) => {
    this.setState({
      show_fancyabondon_confirm: !this.state.show_fancyabondon_confirm,
      formObj: { session_id, is_suspend: false, event_id: event_id },
      event_name
    })
  };

  openModal_confirm_pop_up = (event_id, session_id, runner_name) => {
    this.setState({
      show_confirm_pop_up: !this.state.show_confirm_pop_up,
      formObj: { event_id: event_id, session_id, inputValue: this.props.result },
      runner_name,
    })
  };

  handleModal_confirm_pop_up = () => {
    this.setState({ show_confirm_pop_up: !this.state.show_confirm_pop_up });
  };

  handleModal_fancyabondon_confirm = () => {
    this.setState({ show_fancyabondon_confirm: !this.state.show_fancyabondon_confirm });
  };

  submit_fancyabondon = () => {
    this.setState({ loadingFancyabondon: true });
    let { formObj } = this.state;
    formObj.code = this.state.abandonCode

    this.props.dispatch(
      cancelFancyRequest(formObj, (res) => {

        if (res) {
          this.props.fetchManageFancy();
          this.setState({ show_fancyabondon_confirm: false, loadingFancyabondon: false, abandonCode: '' });
        } else {
          this.setState({ loadingFancyabondon: false });
        }
      })
    );
  };

  submit_postSettleFancy = () => {

    let { formObj } = this.state;
    this.setState({ loadingPostSettleFancy: true })
    this.props.postSettleFancy(formObj.event_id, formObj.session_id, () => {
      this.setState({ show_confirm_pop_up: false, loadingPostSettleFancy: false });
    });


  }

  render() {
    const { show_fancyabondon_confirm, event_name, show_confirm_pop_up, runner_name, loadingFancyabondon, loadingPostSettleFancy, isSportLoading } = this.state;
    const { result, setResult, selectedResult, responseData } = this.props;

    return (
      <>
        <table className="table table-striped table-bordered table-hover dataTables-example">
          <thead>
            <tr>
              <th>S.NO</th>
              <th>DATE</th>
              <th>MATCH NAME</th>
              <th>Fancy NAME</th>
              <th>RESULT</th>
              <th>SETTLE </th>
              <th>ACTION</th>
            </tr>
          </thead>
          <tbody>
            {this.state.isSportLoading && <Loader />}
            {responseData && responseData.length ? (
              responseData.map((match, index) => (
                <tr className="gradeX" key={index}>
                  <td>{index + 1}</td>
                  <td id="getdate_2794093">
                    <div>
                      <i className="fa fa-clock-o"></i>&nbsp;
                      {/* {match.open_date} */}
                      {formatDateAndTime('MMM DD, hh:mm:ss A', match.open_date)}
                    </div>
                  </td>
                  <td>{match.event_name}</td>
                  <td className="center">{match.runner_name}</td>
                  <td className="center">
                    <div className="mng-fnc-form-group form-group">
                      <input
                        name="result"
                        id="0"
                        maxLength="6"
                        type="text"
                        className="text-center form-control"
                        value={
                          selectedResult === match.session_id ? result : ""
                        }
                        onChange={(e) =>
                          setResult(e.target.value, match.session_id)
                        }
                      />
                    </div>
                  </td>
                  <td className="">
                    <div className="action-container">
                      <button
                        type="button"
                        className="action-btn mt-1 btn btn-primary"
                        onClick={() =>

                          this.openModal_confirm_pop_up(
                            match.event_id,
                            match.session_id,
                            match.runner_name
                          )

                        }
                        disabled={
                          !(selectedResult === match.session_id && result)
                        }
                      >
                        Confirm
                      </button>
                    </div>
                  </td>
                  <td>
                    {/* <button
                      type="button"
                      className="action-btn mt-1 btn btn-primary"
                    >
                      Active
                    </button> */}
                    {" "}
                    <button
                      type="button"
                      className="action-btn mt-1 btn btn-primary"
                      onClick={() =>
                        this.openModal_fancyabondon_confirm(
                          match.session_id,
                          match.event_name,
                          match.event_id
                        )
                      }
                    >
                      Abandon
                    </button>{" "}
                    {/* <button
                      type="button"
                      className="action-btn mt-1 btn btn-primary"
                    >
                      Suspend
                    </button> */}
                  </td>
                </tr>
              ))
            ) : (
              <tr className="gradeX">
                <td colSpan={8}>No data available in table</td>
              </tr>
            )}
          </tbody>
        </table>

        {show_confirm_pop_up &&
          <ConfrimModal
            isShow={show_confirm_pop_up}
            message={
              <label>
                Are you sure you want to settle{" "}
                <span style={{ color: "red" }}>{runner_name}</span>{" "} at {" "}
                <span style={{ color: "red" }}>{this.props.result}</span>{" "} runs ?
              </label>
            }
            onCancle={() => this.handleModal_confirm_pop_up()}
            onSubmit={() => this.submit_postSettleFancy()}
            loading={loadingPostSettleFancy}
          />}

        {/* {show_fancyabondon_confirm &&
          <ConfrimModal
            isShow={show_fancyabondon_confirm}
            message={
              <label>
                Are you sure you want to abondon{" "}
                <span style={{ color: "red" }}>{event_name}</span> match?
              </label>
            }
            onCancle={() => this.handleModal_fancyabondon_confirm()}
            onSubmit={() => this.submit_fancyabondon()}
            loading={loadingFancyabondon}
          />} */}

        <Modal
          show={show_fancyabondon_confirm}
          onHide={() => !loadingFancyabondon && this.handleModal_fancyabondon_confirm()}
          style={{ opacity: 1 }}
        >
          <Modal.Header closeButton>Confrim Message</Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <label>
                Please enter 4 digit's verification code.
              </label>
              <input
                placeholder="verification code"
                className="form-control" value={this.state.abandonCode} onChange={(e) => this.setState({ abandonCode: e.target.value })} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => !loadingFancyabondon && this.handleModal_fancyabondon_confirm()}>
              No
            </Button>
            <Button
              type="button"
              onClick={() => !loadingFancyabondon && this.submit_fancyabondon()}
              disabled={!this.state.abandonCode.length}
            >
              {loadingFancyabondon ? <ClipLoader color={"#FFFFFF"} loading={true} size={16} /> : 'Submit'}
            </Button>
          </Modal.Footer>
        </Modal>

      </>
    );
  }
}

export default List;
