import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import InnerPageHeader from "../common/InnerPageHeader";
import Pagination from "../common/Pagination";
import { myLedgersReport } from "../../_services/league.services";
import ReactPaginate from 'react-paginate';

import moment from "moment";

import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import { formatDateAndTime } from "utils";
import { convertParseFloat } from "_helpers";
import Select from "react-select";
import { getSportList } from "_services";
import { useScreenDetector } from "hooks/useScreenDetector";

export default function MyLedgerCmp() {

  const { isMobile } = useScreenDetector()
  const [totalRecords, setTotalRecords] = useState("");

  const [offset, setOffset] = useState(1);
  const [ledger, setLedger] = useState([]);
  const [perPage] = useState(20);
  const [pageCount, setPageCount] = useState(0)
  const [sportsData, setSportsData] = useState([]);
  const [selectedSportOptions, setSelectedSportOptions] = useState([
    { label: "cricket", value: 0 },
    { label: "football", value: 1 },
    { label: "soccer", value: 2 },
  ])

  const [selectedSport, setSelectedSport] = useState('');
  const [newSelectedSport, setNewSelectedSport] = useState([]);

  useEffect(() => {
    fetchAllSports();
  }, []);

  const fetchAllSports = () => {
    getSportList()
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.length
        ) {
          let allSports = response.data.data.map((x) => ({
            label: x.sports_name,
            value: x.sport_id,
          }));
          setSportsData(response.data.data);
          setSelectedSportOptions(allSports);
        } else {
          setSportsData([]);
          setSelectedSportOptions([]);
        }
      })
      .catch((error) => {
        setSportsData([]);
        setSelectedSportOptions([]);
      });
  };

  const handleChange = useCallback((selectedSport) => {
    setSelectedSport(selectedSport);  // Update selectedSport state

    getMyLedgersReport(selectedSport);  // Pass selectedSport to the report function

    if (selectedSport && selectedSport.length > 0) {
      setNewSelectedSport([...selectedSport]);
    } else {
      setNewSelectedSport([]);
    }
  }, []);


  useEffect(() => {
    getMyLedgersReport()
  }, [offset]);

  const getMyLedgersReport = (selectedSport) => {
    const userLogin = JSON.parse(localStorage.getItem("userLogin"));

    let sendData = {};
    sendData.user_guid = userLogin?.user_guid;
    sendData.current_page = offset;
    sendData.items_perpage = perPage;
    sendData.from_date = "";
    sendData.to_date = "";
    sendData.sports_id = selectedSport && selectedSport.length
      ? selectedSport.map((item) => item.value)
      : [];
    myLedgersReport(sendData)
      .then((response) => {
        if (response && response.data && response.data.data) {

          setLedger(response?.data?.data?.ledger);

          setPageCount(Math.ceil(response?.data?.data?.total_records / perPage))
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setOffset(selectedPage + 1)
  };

  const [state, setState] = useState({
    // start: moment().subtract(29, 'days'),
    // end: moment(),

    start: null,
    end: null,
  });

  const { start, end } = state;

  const handleCallback = (start, end) => {
    setState({ start, end });

    let dateObject = {
      fromDate: moment(
        moment.utc(start).local().format("YYYY-MM-DD 00:00:00"),
        "YYYY-MM-DD HH:mm:ss"
      )
        .utc()
        .format("YYYY-MM-DD HH:mm:ss"),
      toDate: moment(
        moment.utc(end).local().format("YYYY-MM-DD 23:59:00"),
        "YYYY-MM-DD HH:mm:ss"
      )
        .utc()
        .format("YYYY-MM-DD HH:mm:ss"),
    };

    const userLogin = JSON.parse(localStorage.getItem("userLogin"));

    let sendData = {};
    sendData.user_guid = userLogin?.user_guid;
    // sendData.current_page = 1;
    // sendData.items_perpage = 100;
    sendData.current_page = offset;
    sendData.items_perpage = perPage;

    sendData.from_date = dateObject.fromDate;
    sendData.to_date = dateObject.toDate;
    myLedgersReport(sendData)
      .then((response) => {
        if (response && response.data && response.data.data) {

          setLedger(response?.data?.data?.ledger);

          setPageCount(Math.ceil(response?.data?.data?.total_records / perPage))

        }
      })
      .catch((error) => {
        setLedger([]);
      });
  };




  const label =
    start && end
      ? start.format("MMMM D, YYYY") + " - " + end.format("MMMM D, YYYY")
      : null;
  return (
    <>
      <InnerPageHeader
        pageTitle="My-Ledgers"
        breadcrumbs={[{ title: "Dashboard", url: "/" }]}
        currenPageTitle="My-Ledgers"
      />


      <div className="container-fluid">
        <div className={"row " + (!isMobile ? "d-flex" : "")} style={{ alignItems: 'center' }}>
          <div className="col-lg-3 col-md-4 col-sm-12">
            <Select
              options={selectedSportOptions}
              onChange={handleChange}
              isMulti
              value={selectedSport ? selectedSport : ""}
              className="reactSelect"
              placeholder=""
            />
          </div>


          <div className="col-lg-7 col-md-4 col-sm-12">
            <DateRangePicker
              initialSettings={{
                // startDate: start.toDate(),
                // endDate: end.toDate(),
                startDate: start ? start.toDate() : moment().subtract(29, "days"),
                endDate: end ? end.toDate() : moment(),
                ranges: {
                  Today: [moment().toDate(), moment().toDate()],
                  Yesterday: [
                    moment().subtract(1, "days").toDate(),
                    moment().subtract(1, "days").toDate(),
                  ],
                  "Last 7 Days": [
                    moment().subtract(6, "days").toDate(),
                    moment().toDate(),
                  ],
                  "Last 30 Days": [
                    moment().subtract(29, "days").toDate(),
                    moment().toDate(),
                  ],
                  "This Month": [
                    moment().startOf("month").toDate(),
                    moment().endOf("month").toDate(),
                  ],
                  "Last Month": [
                    moment().subtract(1, "month").startOf("month").toDate(),
                    moment().subtract(1, "month").endOf("month").toDate(),
                  ],
                },
              }}
              onCallback={handleCallback}
            >
              <div
                id="reportrange"
                className="col-4"
                style={{
                  background: "#fff",
                  cursor: "pointer",
                  padding: "5px 10px",
                  border: "1px solid #ccc",
                  width: "25%",
                  height: "35px",
                }}
              >
                <span style={{ float: "right" }}>
                  <i className="fa fa-calendar"></i>&nbsp;
                  <span> {label ? label : "-"} </span>{" "}
                  <i className="fa fa-caret-down"></i>
                </span>
              </div>
            </DateRangePicker>
          </div>
        </div>
      </div>

      <div className="wrapper wrapper-content animated fadeInRight">
        <div className="row">
          <div className="col-12">
            <div className="ibox">
              <div className="ibox-title">
                <h5>My Ledgers</h5>
              </div>
              <div className="ibox-content">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="table-responsive">
                      <table className="table table-striped table-bordered table-hover dataTables-example">
                        <thead>
                          <tr>
                            <th>S No.</th>
                            <th>LEDGER ID</th>
                            <th>DATE/TIME</th>
                            <th>Collection-Name</th>
                            <th>Lena</th>
                            <th>Dena</th>
                            <th>Balance</th>
                            <th>Note</th>
                          </tr>
                        </thead>
                        <tbody>
                          {ledger?.map((el, ind) => {
                            return (
                              <tr key={ind}>
                                <td>{ind + 1}</td>
                                <td> {el.txn_no} </td>
                                <td>{el.created_at
                                  ? formatDateAndTime(
                                    "MMM DD, hh:mm:ss A",
                                    el.created_at
                                  ) : ""
                                }</td>
                                <td>{el.source == 8 ? <a href={'show_settlement/' + el.source_id + '/' + el.user_id}>{el.remark}</a> : el.remark}</td>
                                <td>{convertParseFloat(el.credit_amount)}</td>
                                <td>{convertParseFloat(el.debit_amount)}</td>
                                <td>{convertParseFloat(el.ledger_balance)}</td>
                                <td> {el.note ? el.note : ""} </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>

                    <div className="paginate_class">
                      <ReactPaginate
                        previousLabel={"prev"}
                        nextLabel={"next"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
